import Vue from 'vue'
import Vuex from 'vuex'
import { twitter } from "./twitter.module"
import { account } from './account.module';
import { alerts } from './alerts.module';
//import { users } from './users.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        twitter,
        account,
        alerts
        // users
    },
    state: {
        sidebarShow: 'responsive',
        sidebarMinimize: false,
        asideShow: false,
        darkMode: false,
    },
    mutations: {
        toggleSidebarDesktop(state) {
            const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
            state.sidebarShow = sidebarOpened ? false : 'responsive'
        },
        toggleSidebarMobile(state) {
            const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
            state.sidebarShow = sidebarClosed ? true : 'responsive'
        },
        set(state, [variable, value]) {
            state[variable] = value
        },
        toggle(state, variable) {
            state[variable] = !state[variable]
        }
    },
    getters: {},
    actions: {}
})

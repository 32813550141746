import {
    cibSkype,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex
} from '@coreui/icons'
import {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
} from '@coreui/icons'
import {
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilBold,
    cilBookmark,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDrop,
    cilDollar,
    cilDoubleQuoteSansLeft,
    cilExternalLink,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilFilter,
    cilFullscreen,
    cilFullscreenExit,
    cilHome,
    cilHeart,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLoop,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMap,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPrint,
    cilPuzzle,
    cilSave,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilXCircle,
    cilWarning
} from '@coreui/icons'
import {logo} from './logo'


export const iconsSet = Object.assign({}, {logo}, {
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilBold,
    cilBookmark,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDrop,
    cilDollar,
    cilDoubleQuoteSansLeft,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilExternalLink,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilHeart,
    cilFile,
    cilFullscreen,
    cilFullscreenExit,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLaptop,
    cilLayers,
    cilLoop,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMap,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPrint,
    cilPuzzle,
    cilSave,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilXCircle,
    cilWarning,
    cilFilter
}, {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
}, {
    cibSkype,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex
})
